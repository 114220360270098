import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import  { useEffect, useState }  from 'react';
import axios from 'axios';
import { errorNotify, successNotify } from '../../../toaster';
import { useAuth } from '../../../hooks/useAuthContext';
import { appURL } from '../../../env';

export default function EditCategory({ show, closeModal, refreshPage,category }) {
  const { user } = useAuth()
  const token = user.token

  const [validationError, setValidationError] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  //  state to hold form data
  const [formData, setFormData] = useState({
    name: '',
    status: '',
    description:''
  })
  //  method to reset form fields to empty
  const resetForm = () => {
    setFormData({
        name: '',
        status: '',
        description:''
    })
    setValidationError({})
  }

  //  listen for changes on form inputs and store in state
  const handleChange = (e) => {
    setFormData(prevFormData => (
      { ...prevFormData, [e.target.name]: e.target.value }
    ))
  }

  //  method to call then modal is closed
  const handleClose = () => {
    resetForm()
    closeModal()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    // return console.log(formData);

    // return console.log(formData);
    await axios
      .post(`${appURL}/admin/event-category`, {...formData,id: category.id}, {
        headers: {
          "Accept": "application/json",
          "Content-Type": 'multipart/form-data',
          "Authorization": `Bearer ${token}`
        }
      })
      .then((res) => {
        // console.log(res.data);
        successNotify(res.data.message)
        handleClose()
        refreshPage()
      })
      .catch(error => {
        if (error.response.status === 420) {
          setValidationError(error.response.data.errors)
          errorNotify('An error occured!')
        } else {
        //   console.log(error.response.data.message)
          errorNotify('An error occured. Please, try again or reload page.')
        }
      })
      .finally(() => {
        setIsLoading(false)
      });
  }
  useEffect(() => {
    if (category) {
      setFormData({
        name: category.name || '',
        description: category.description || '',
        status: category.status || ''
      });
    }
  }, [category]);
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Event Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name='name'
                value={formData.name}
                onChange={handleChange}
                required
              />
              {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={2}  value={formData.description}
              placeholder="Enter..."
              name='description'
              onChange={handleChange}/>
              {validationError.description && <small className='text-danger my-1'>{validationError.description[0]}</small>}
            </Form.Group>

            <div className='mb-3'>
              <label htmlFor='status' style={{ display: 'block' }}>
                <span>Status</span>
                <select
                  className='form-control mt-2'
                  id='status'
                  value={formData.status}
                  onChange={handleChange}
                  name='status'
                >
                  <option value=''>--Select Status--</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </div>
  );
}