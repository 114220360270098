import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { appURL } from '../../env';
import { useAuth } from '../../hooks/useAuthContext';
import axios from 'axios';
import { errorNotify, successNotify } from '../../toaster';
import LoadingOverlay from 'react-loading-overlay';
import { SpinnerComponent } from 'react-element-spinner';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// components
import CreateVideo from './Partials/Create';
import PreviewVideo from './Partials/Video';
import EditVideo from './Partials/Edit';
import { blue } from '@mui/material/colors';
import './Partials/style.css'
import GoogleLogin from 'react-google-login';

export default function EventPage() {

    // const [showEdit, setShowEdit] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [singleVideo, setSingleVideo] = useState(null);
    const [setFile, setFileEvent] = useState(null);
    const [videoStatus, setVideoStatus] = useState({});
    const [deleteVideoId, setDeleteVideoId] = useState(null);

    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    // import Modal from 'react-bootstrap/Modal';
    // import Button from 'react-bootstrap/Button';
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { user } = useAuth();
    const token = user.token;
    // function to handle file modal
    const handleUploadFile = async (e) => {
        await setFileEvent(e);
        await setShowUpload(true);
    };

    const [show, setShow] = useState(false);
    const [videoData, passVideo] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [showVideoEdit, setShowEdit] = useState(false);
    const [video, setVideo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentVideo = video.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(video.length / itemsPerPage);
    // const [eventCategory, setEventCatgory] = useState([])

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return; // Skip page change if the pageNumber is out of bounds
        }
        setCurrentPage(pageNumber);
    };
    const handleShow = () => setShow(true);
    const handleShowVideo = () => setShowVideo(true);
    const handleShowEdit = () => setShowEdit(true);
    const PassVideoData = (data) => { passVideo(data); }
    // const PassVideoData = (data) => { passVideo(data); }

    const handleClose = () => {
        setShowVideo(false);
        setShow(false);
        setShowEdit(false);
        setShowUpload(false);
    };
    const handleDeleteCancel = () => {
        setDeleteVideoId(null);
        setDeleteOpen(false);
    };
    const handleDeleteConfirm = () => {
        setIsDeleteLoading(true);
        // return console.log(deleteCategoryId);
        axios
            .delete(`${appURL}/admin/video/remove/${deleteVideoId}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                successNotify(res.data.message);
                handleRefresh();
            })
            .catch((error) => {
                // console.log(error.response.data.message);
                errorNotify(
                    'An error occurred. Please, try again or reload the page.'
                );
            })
            .finally(() => {
                setIsDeleteLoading(false);
                setDeleteVideoId(null);
                setDeleteOpen(false);
            });
    };
    const deleteVideo = (id) => {
        setDeleteVideoId(id);
        setDeleteOpen(true);
    };
    
    const handleRefresh = () => {
        setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
    };

    const fetchVideo = async () => {
        console.log(111);
        setIsLoading(true);
        fetch('https://www.googleapis.com/youtube/v3/search?key=AIzaSyDMOXKUk2IsQtxuPnPnBd-X2LLGdETeftE&channelId=UCdkA_GWhhJAGY5z1dDWarLw&part=snippet,id')
            .then(res => res.json())
            .then((res) => {
                console.log();
                // const fetchedBanners = res.data.data;
                // setVideo(res.items);
                if (res.items.length > 0) {
                    res.items = [...res.items];
                    res.items.shift(); // Remove the first item
                    setVideo(res.items);
                }
                setIsLoading(false);
            })
    };

    const handleStatusChange = (id) => {
        axios
            .post(
                `${appURL}/admin/event/update-status`,
                { id },
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                successNotify(res.data.message);
                handleRefresh();
            })
            .catch((error) => {
                console.log(error.response.data.message);
                errorNotify(
                    'An error occurred. Please try again or reload the page.'
                );
            });
    };

    useEffect(() => {
        fetchVideo();
    }, [refreshKey]);

    return (
        <div>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading. Please, wait...'
            >
                <div className='content-wrapper'>
                    <div className='page-header'>
                        <h3 className='page-title'>
                            <span className='page-title-icon bg-gradient-primary text-white me-2'>
                                <i className='mdi mdi-vector-difference-ba'></i>
                            </span>{' '}
                            Manage Video
                        </h3>
                        <nav aria-label='breadcrumb'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to='/dashboard'>Dashboard</Link>
                                </li>
                                <li className='breadcrumb-item active' aria-current='page'>
                                    video
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row'>
                        <div
                            className='col-lg-12 col-md-12 order-2 order-sm-1 grid-margin stretch-card'
                        >
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='d-flex mb-4'>
                                        <h4 className='card-title d-flex align-items-center'>
                                            All Video
                                        </h4>
                                        <button
                                            className='btn btn-gradient-primary btn-rounded btn-icon ml-auto'
                                            title='New Banner'
                                            onClick={handleShow}
                                        >
                                            <i className='mdi mdi-plus-circle-outline'></i>
                                        </button>
                                    </div>
                                    {video.length > 0 && (
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    {
                                                        video.map((v, i) => (
                                                            <div className="col-md-3" key={i}>
                                                                <div className="card">
                                                                    <div className="card-body p-0" style={{ objectFit: 'cover' }}>
                                                                        {/* <video autoPlay controls className='w-100 no-playback-controls' id='myVideo' onClick={() => {handleShowVideo();PassVideoData(v)}}>
                                                                            <source src={`https://www.youtube.com/watch?v=`+ v.id.videoId} type={v.video_type} />
                                                                        </video> */}
                                                                        <iframe
                                                                            title={`Video ${i}`}
                                                                            width="100%"
                                                                            height="200"
                                                                            src={`https://www.youtube.com/embed/${v.id.videoId}`}
                                                                            frameBorder="0"
                                                                            allowFullScreen
                                                                        ></iframe>
                                                                        <div className='p-1'>
                                                                            <div className='d-flex'>
                                                                                <span className='d-block'><i className="fa fa-trash" aria-hidden="true" onClick={() => deleteVideo(v.id.videoId)}></i></span>
                                                                                <i className='d-block mdi mdi-pencil mr-2 text-success' onClick={() => { handleShowEdit(); PassVideoData(v) }}></i>
                                                                            </div>
                                                                            <h5 className='title'>{v.title}</h5>
                                                                            <p className='sermon-by'>{v.sermon_by}</p>
                                                                            <p className='date'>{v.created_at}</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {currentVideo.length === 0 && (
                                        <div className='alert alert-warning text-center'>
                                            No Video Available
                                        </div>
                                    )}
                                    <div className="pagination d-flex justify-content-center mt-4 mb-0">
                                        <button
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                            className="btn btn-primary rounded-pill me-2"
                                            style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                                        >
                                            Previous
                                        </button>
                                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                                            <button
                                                key={page}
                                                onClick={() => handlePageChange(page)}
                                                className={`btn btn-primary rounded-pill me-2 ${currentPage === page ? 'active' : ''
                                                    }`}
                                                style={
                                                    currentPage === page
                                                        ? { backgroundColor: '#007bff', borderColor: '#007bff' }
                                                        : { backgroundColor: '#b8d2ec', borderColor: '#ced4da' }
                                                }
                                            >
                                                {page}
                                            </button>
                                        ))}
                                        <button
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                            className="btn btn-primary rounded-pill"
                                            style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
            </LoadingOverlay>
            <CreateVideo
                show={show}
                closeModal={handleClose}
                refreshPage={handleRefresh}
            />
            <PreviewVideo
                show={showVideo}
                closeModal={handleClose}
                refreshPage={handleRefresh}
                video={videoData}
            />
            <EditVideo
                show={showVideoEdit}
                closeModal={handleClose}
                refreshPage={handleRefresh}
                video={videoData}
            />
            <Modal show={deleteOpen} onHide={handleDeleteCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete ? Once Done cant be reversed</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleDeleteCancel}>
                        Cancel
                    </Button>
                    <SpinnerComponent
                        loading={isDeleteLoading}
                        message='Processing...'
                        position='centered'
                    />
                    <Button
                        variant='danger'
                        onClick={handleDeleteConfirm}
                        disabled={isDeleteLoading}
                    >
                        {isDeleteLoading ? 'Deleting...' : 'Delete'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
