import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddBanner from './AddBanner';
import { appURL } from '../../env';
import { useAuth } from '../../hooks/useAuthContext';
import axios from 'axios';
import { errorNotify, successNotify } from '../../toaster';
import LoadingOverlay from 'react-loading-overlay';
import EditBanner from './EditBanners';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { SpinnerComponent } from 'react-element-spinner';
import Carousel from 'react-bootstrap/Carousel';

export default function BannersPage() {
  const { user } = useAuth();
  const token = user.token;

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [singleBanner, setSingleBanner] = useState(null);
  const [deleteBanner, setDeleteBanner] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [bannerStatus, setBannerStatus] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const handleShow = () => setShow(true);
  const handleShowEdit = async (banner) => {
    await setSingleBanner(banner);
    await setShowEdit(true);
  };
  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setChangeImage(false);
  };

  const handleStatusChange = (id) => {
    const newStatus = bannerStatus[id] === 'active' ? 'inactive' : 'active';

    axios
      .post(
        `${appURL}/background-slider/update-status`,
        { id, status: newStatus },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        successNotify(res.data.message);
        setBannerStatus((prevStatus) => ({
          ...prevStatus,
          [id]: newStatus,
        }));
      })
      .catch((error) => {
        console.log(error.response.data.message);
        errorNotify(
          'An error occurred. Please try again or reload the page.'
        );
      });
  };

  const fetchBanners = async () => {
    setIsLoading(true);
    await axios
      .get(`${appURL}/background-slider/fetchBanners`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const fetchedBanners = res.data.data;

        const initialStatus = {};
        fetchedBanners.forEach((banner) => {
          initialStatus[banner.id] = banner.status;
        });
        setBannerStatus(initialStatus);
        setBanners(fetchedBanners);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        errorNotify(
          'An error occurred. Please, try again or reload the page.'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelete = (banner) => {
    setDeleteBanner(banner);
    setDeleteOpen(true);
  };

  const handleDeleteConfirm = () => {
    setIsDeleteLoading(true);
    axios
      .delete(`${appURL}/background-slider/${deleteBanner.id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        successNotify(res.data.message);
        handleRefresh();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        errorNotify(
          'An error occurred. Please, try again or reload the page.'
        );
      })
      .finally(() => {
        setIsDeleteLoading(false);
        setDeleteBanner(null);
        setDeleteOpen(false);
      });
  };

  const handleDeleteCancel = () => {
    setDeleteBanner(null);
    setDeleteOpen(false);
  };

  const handleRefresh = () => {
    setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
  };

  useEffect(() => {
    fetchBanners();
  }, [refreshKey]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBanners = banners.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(banners.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) {
      return; // Skip page change if the pageNumber is out of bounds
    }
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner
        text='Loading. Please, wait...'
      >
        <div className='content-wrapper'>
          <div className='page-header'>
            <h3 className='page-title'>
              <span className='page-title-icon bg-gradient-primary text-white me-2'>
                <i className='mdi mdi-vector-difference-ba'></i>
              </span>{' '}
              Banners
            </h3>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Manage banners
                </li>
              </ol>
            </nav>
          </div>
          <div className='row'>
            <div
              className={`${
                banners.length > 0 ? 'col-lg-8' : 'col-lg-12'
              } order-2 order-sm-1 grid-margin stretch-card`}
            >
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex mb-4'>
                    <h4 className='card-title d-flex align-items-center'>
                      Page Banners
                    </h4>
                    <button
                      className='btn btn-gradient-primary btn-rounded btn-icon ml-auto'
                      title='New Banner'
                      onClick={handleShow}
                    >
                      <i className='mdi mdi-plus-circle-outline'></i>
                    </button>
                  </div>
                  {banners.length > 0 && (
                    <div className='scroll-area-lg'>
                      <div className='table-responsive-sm'>
                        <table className='table table-hover'>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Banners Image</th>
                              <th>Link</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentBanners.map((banner, i) => (
                              <tr key={banner.id}>
                                <td>{indexOfFirstItem + i + 1}</td>
                                <td>
                                  <div className='d-flex align-items-center text-capitalize'>
                                    <img
                                      src={banner.slider_image}
                                      className='rounded-lg mr-3'
                                      alt='banner image'
                                    />
                                    {banner.slider_text}
                                  </div>
                                </td>
                                <td>{banner.link}</td>
                                <td>
                                  <span
                                    className='updateBannerStatus'
                                    onClick={() => handleStatusChange(banner.id)}
                                  >
                                    {bannerStatus[banner.id] === 'active' ? (
                                      <i
                                        style={{ fontSize: '25px' }}
                                        className='mdi mdi-bookmark-check'
                                      ></i>
                                    ) : (
                                      <i
                                        style={{ fontSize: '25px' }}
                                        className='mdi mdi-bookmark-outline'
                                      ></i>
                                    )}
                                  </span>
                                </td>
                                <td className='dropdown'>
                                  <Link
                                    className='dropdown-toggle menu'
                                    id='actionDropdown'
                                    href='#'
                                    data-bs-toggle='dropdown'
                                    aria-expanded='false'
                                  >
                                    <i className='mdi mdi-apps'></i>
                                  </Link>
                                  <div
                                    className='dropdown-menu dropdown-menu-right preview-list'
                                    aria-labelledby='actionDropdown'
                                  >
                                    <Link
                                      className='dropdown-item custom-drop'
                                      onClick={() => handleShowEdit(banner)}
                                    >
                                      <i className='mdi mdi-pencil mr-2 text-success'></i>{' '}
                                      <span>Edit</span>
                                    </Link>
                                    <div className='dropdown-divider'></div>
                                    <Link
                                      className='dropdown-item custom-drop'
                                      onClick={() => handleDelete(banner)}
                                    >
                                      <i className='mdi mdi-trash-can-outline mr-2 text-danger'></i>{' '}
                                      <span>Delete</span>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {banners.length === 0 && (
                    <div className='alert alert-warning text-center'>
                      No Record
                    </div>
                  )}
                </div>
              </div>
            </div>
            {banners.length > 0 && (
              <div className='col-lg-4 grid-margin order-1 order-sm-2'>
                <div className='card'>
                  <div className='card-body'>
                    <h4 className='mb-4'>Preview</h4>
                    <Carousel>
                      {banners.map((banner) => (
                        <Carousel.Item key={banner.id}>
                          <img
                            className='d-block w-100'
                            src={banner.slider_image}
                            alt='First slide'
                          />
                          <Carousel.Caption>
                            <h3>{banner.slider_text}</h3>
                          </Carousel.Caption>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </div>
              </div>
            )}
          </div>
           <div className="pagination d-flex justify-content-center">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="btn btn-primary rounded-pill me-2"
                    style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                >
                    Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                    <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`btn btn-primary rounded-pill me-2 ${
                        currentPage === page ? 'active' : ''
                    }`}
                    style={
                        currentPage === page
                        ? { backgroundColor: '#007bff', borderColor: '#007bff' }
                        : { backgroundColor: '#b8d2ec', borderColor: '#ced4da' }
                    }
                    >
                    {page}
                    </button>
                ))}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="btn btn-primary rounded-pill"
                    style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                >
                    Next
                </button>
            </div>
            


        </div>
      </LoadingOverlay>
      <AddBanner
        show={show}
        closeModal={handleClose}
        refreshPage={handleRefresh}
      />
      <EditBanner
        show={showEdit}
        closeModal={handleClose}
        refreshPage={handleRefresh}
        banner={singleBanner}
      />

      <Modal show={deleteOpen} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this banner?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <SpinnerComponent
            loading={isDeleteLoading}
            message='Processing...'
            position='centered'
          />
          <Button
            variant='danger'
            onClick={handleDeleteConfirm}
            disabled={isDeleteLoading}
          >
            {isDeleteLoading ? 'Deleting...' : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>

      
    </div>
  );
}
