import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { appURL } from "../env"
import { useAuth } from "./useAuthContext"

export const useLogout = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [apiError, setApiError] = useState(null)
    const navigate = useNavigate()

    const { dispatch }  = useAuth()

    const logout = async () => {
        setApiError(null)
        setIsLoading(true)

        await axios.post(`${appURL}/auth/admin/logout`, {}, {
            headers: {
                "Accept": "*/*",
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                // "Authorization" : `Bearer ${user.token}`
            },
        })
            .then(()=>{
                localStorage.removeItem('userDetails')
                dispatch({ type: 'LOGOUT'})
                navigate("/login")
            }).catch(({response})=>{
                setApiError(response.data.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return  { logout, isLoading, apiError }
}