import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Dashboard from "./Dashboard";
import BannerPage from "./Pages/ManageBanners/Banner";
import EventCategory from "./Pages/EventProgramme/Category/Index";
import Event from "./Pages/EventProgramme/Event/Index";
import EventTag from "./Pages/EventProgramme/Tag/Index";
import Video from "./Pages/ManageVideo/Index";
import GalleryPage from "./Pages/ManageGallery/Index";
// import EventProgrammePage from './Pages/EventProgramme/event'
// import EditBannerPage from "./Pages/ManageBanners/EditBanner";
import BaseLayout from "./components/Layouts/BaseLayout";
import { useAuth } from "./hooks/useAuthContext";
import LoginPage from "./LoginPage";


export default function CustomRouter() {
  const { user } = useAuth()

  const routes = useRoutes([
      {
        path: '/', 
        element: user ? <BaseLayout /> : <Navigate to="/login" />,
          // element: <BaseLayout />,
          children: [
              { element: <Navigate to="/dashboard" />, index: true },
              { path: 'dashboard', element: <Dashboard/>},
              { path: 'banners', element: <BannerPage />},
              { path: 'event-category', element: <EventCategory /> },
              { path: 'event', element: <Event /> },
              { path: 'tag', element: <EventTag /> },
              { path: 'video', element: <Video /> },
              { path: 'gallery', element: <GalleryPage /> },
              // { path: 'event', element: <EventProgrammePage /> },
              // { path: 'categories', element: <CategoriesIndex />},
              // { path: 'products', element: <ProductsPage />},
              // { path: 'pickup_stations', element: <PickupIndex />},
              // { path: 'product_utils/:product_id', element: <SizesandColors />},
          ]
      },
      {
          path: 'login',
          element: user ? <Navigate to="/dashboard"/> : <LoginPage />,
      },
  ])

  return routes
}
