import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "./useAuthContext"

import { appURL } from "../env"

export const useLogin = () => {
    const [isLoading, setIsLoading] = useState(false)
    // const [apiData, setApiData] = useState([])
    const [apiError, setApiError] = useState(null)
    const [validationError, setValidationError] = useState({})
    const navigate = useNavigate()

    const { dispatch }  = useAuth()

    const login = async (formData) => {
        setApiError(null)
        setValidationError({})
        setIsLoading(true)

        await axios.post(`${appURL}/auth/admin/login`, formData, {
            headers: {
                "Accept": "application/json",
            },
        })
            .then(({data})=>{
                const customData = {
                    user: data.user,
                    token: data.access_token
                }
                localStorage.setItem('userDetails', JSON.stringify(customData))
                dispatch({ type: 'LOGIN', payload: customData })
                navigate("/")
            }).catch(({response})=>{
                if(response.status===422){
                    setValidationError(response.data)
                }else{
                    setApiError("Incorrect Email or Password")
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return  { login, isLoading, apiError, validationError }
}