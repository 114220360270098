import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    const [currentYear, setCurrentYear] = useState()

    const getYear = () => {
        const d = new Date();
        let year = d.getFullYear();
        setCurrentYear(year);
    }

    useEffect(() => {
        getYear()
    }, [currentYear])

    return (
        <footer className="footer">
            <div className="container-fluid">
                <span className="text-muted d-block text-center">Copyright © Grace Definition Church {currentYear}</span>
            </div>
        </footer>
    )
}