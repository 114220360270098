import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import { useState ,useEffect} from 'react';
import axios from 'axios';
import { errorNotify, successNotify } from '../../../../toaster';
import { useAuth } from '../../..//../hooks/useAuthContext';
import { appURL } from '../../../../env';
import Image from 'react-bootstrap/Image';
export default function AddEvent({ show, closeModal, refreshPage, file_data }) {
    const { user } = useAuth()
    const token = user.token
    const [validationError, setValidationError] = useState({})
    const [formData, setFormData] = useState({
        file_url: ''
    })
    //  state to hold form data
    const [file_url, setFileUrl] = useState('');
    // //  method to reset form fields to empty
    const resetForm = () => {
        setFormData({
            event_image: ''
        })
        setValidationError({})
    }

    //  method to call then modal is closed
    const handleClose = () => {
        resetForm()
        closeModal()
    }

    const changeHandler = (event) => {
        // return console.log(event);
        setValidationError({})
        const { name, files } = event.target
        // return console.log(name, files);
        setFormData(prevFormData => (
            {...prevFormData, [name]: files[0] }
        ));
        setFileUrl(URL.createObjectURL(files[0]))
    };
    const handleUploadFile = (e) => {
        e.preventDefault()
        // return console.log(formData.event_image);
        axios
            .post(
                `${appURL}/admin/event/upload_event_image`,
                { 
                    'id': file_data.id,
                    'event_image':formData.event_image
                },
                {
                    headers: {
                        Accept: 'application/json',
                        "Content-Type": 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                successNotify(res.data.message);
                refreshPage()
                handleClose()
            })
            .catch((error) => {
                console.log(error.response.data.message);
                errorNotify(
                    'An error occurred. Please try again or reload the page.'
                );
            });
    }
    useEffect(() => {
        if(file_data){
            setFileUrl(file_data.event_image || '');
        }
    }, [file_data]);
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleUploadFile}>
                    <Modal.Header closeButton>
                        <Modal.Title>Event File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Group className="mb-3" controlId="formBasicname">
                            <Form.Label>Name</Form.Label>
                            <div>
                               <Image alt="No Image set" src={file_url} fluid /> 
                            </div>
                            <Form.Control
                                type="file"
                                placeholder="Enter..."
                                onChange={changeHandler}
                                accept=".jpg, .jpeg, .png"
                                name="event_image"
                                required
                            />
                            {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}
                        </Form.Group>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </div>
    );
}