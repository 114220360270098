import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
// import { BrowserRouter as Router } from "react-router-dom";
import CustomRouter from "./routes";
import { useAuth } from './hooks/useAuthContext';
// import MyComponent from 'react-fullpage-custom-loader'
// import routes from "./routes";
// import MyComponent from 'react-fullpage-custom-loader'

// function App() {
//   return (
//     <>
//       {/* <MyComponent/> */}
//       <CustomRouter />
//     </>
//   );
// }

// export default App;

function App() {
  const { authIsReady } = useAuth()

  return (
      <div className="App">
        {/* {!authIsReady && (<MyComponent 
          sentences={[]}
          loaderType={'ball-triangle-path'}
        />)} */}
        {authIsReady && (<CustomRouter />)}
      </div>
  );
}

export default App;

