import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { appURL } from '../../../env';
import { useAuth } from '../../../hooks/useAuthContext';
import axios from 'axios';
import { errorNotify, successNotify } from '../../../toaster';
import LoadingOverlay from 'react-loading-overlay';
import { SpinnerComponent } from 'react-element-spinner';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// components
import CreateTag from './Partials/Create';
import EditTag from './Partials/EditTag';

export default function BannersPage() {
    const [showEdit, setShowEdit] = useState(false);
    const [singleTag, setSingleTag] = useState(null);
    const [tagStatus, setTagStatus] = useState({});
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [deleteTagId, setDeleteTagId] = useState(null);

    // import Modal from 'react-bootstrap/Modal';
    // import Button from 'react-bootstrap/Button';
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { user } = useAuth();
    const token = user.token;
    const handleShowEdit = async (e) => {
        await setSingleTag(e);
        await setShowEdit(true);
    };
    const [show, setShow] = useState(false);
    const [Tag, setTag] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTag = Tag.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(Tag.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return; // Skip page change if the pageNumber is out of bounds
        }
        setCurrentPage(pageNumber);
    };
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setShowEdit(false);
    };
    const handleDelete = (id) => {
        setDeleteTagId(id);
        setDeleteOpen(true);
    };
    const handleRefresh = () => {
        setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
    };
    const fetchTag = async () => {
        setIsLoading(true);
        await axios
            .post(`${appURL}/admin/tag/all`, null, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // const fetchedBanners = res.data.data;
                setTag(res.data.data.data);
            })
            .catch((error) => {
                console.log(error.response.data.message);
                errorNotify(
                    'An error occurred. Please, try again or reload the page.'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const handleStatusChange = (id) => {
        axios
          .post(
            `${appURL}/admin/tag/update-status`,
            { id },
            {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            successNotify(res.data.message);
            handleRefresh();
          })
          .catch((error) => {
            console.log(error.response.data.message);
            errorNotify(
              'An error occurred. Please try again or reload the page.'
            );
          });
      };
      const handleDeleteCancel = () => {
        setDeleteTagId(null);
        setDeleteOpen(false);
      };
      const handleDeleteConfirm = () => {
        setIsDeleteLoading(true);
        // return console.log(deleteCategoryId);
        axios
          .delete(`${appURL}/admin/tag/remove/${deleteTagId}`, {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            successNotify(res.data.message);
            handleRefresh();
          })
          .catch((error) => {
            console.log(error.response.data.message);
            errorNotify(
              'An error occurred. Please, try again or reload the page.'
            );
          })
          .finally(() => {
            setIsDeleteLoading(false);
            setDeleteTagId(null);
            setDeleteOpen(false);
          });
      };
    useEffect(() => {
        fetchTag();
    }, [refreshKey]);

    return (
        <div>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Loading. Please, wait...'
            >
                <div className='content-wrapper'>
                    <div className='page-header'>
                        <h3 className='page-title'>
                            <span className='page-title-icon bg-gradient-primary text-white me-2'>
                                <i className='mdi mdi-vector-difference-ba'></i>
                            </span>{' '}
                            Event Tag
                        </h3>
                        <nav aria-label='breadcrumb'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to='/dashboard'>Dashboard</Link>
                                </li>
                                <li className='breadcrumb-item active' aria-current='page'>
                                    Manage Event Tag
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row'>
                        <div
                            className='col-lg-12 col-md-12 order-2 order-sm-1 grid-margin stretch-card'
                        >
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='d-flex mb-4'>
                                        <h4 className='card-title d-flex align-items-center'>
                                            Tags
                                        </h4>
                                        <button
                                            className='btn btn-gradient-primary btn-rounded btn-icon ml-auto'
                                            title='New Banner'
                                            onClick={handleShow}
                                        >
                                            <i className='mdi mdi-plus-circle-outline'></i>
                                        </button>
                                    </div>
                                    {Tag.length > 0 && (
                                        <div className='scroll-area-lg'>
                                            <div className='table-responsive-sm'>
                                                <table className='table table-hover'>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentTag.map((ec, i) => (
                                                            <tr key={ec.id}>
                                                                <td>{indexOfFirstItem + i + 1}</td>
                                                                <td>
                                                                    <div className='d-flex align-items-center text-capitalize'>

                                                                        {ec.name}
                                                                    </div>
                                                                </td>
                                                                <td>{
                                                                    ec.status == 'active' ? <Badge bg="success">{ec.status}
                                                                    </Badge> :
                                                                        <Badge bg="danger">{ec.status}
                                                                        </Badge>
                                                                }
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className='updateBannerStatus'
                                                                        onClick={() => handleStatusChange(ec.id)}
                                                                    >
                                                                        {ec.status === 'active' ? (
                                                                            <i
                                                                                style={{ fontSize: '25px' }}
                                                                                className='mdi mdi-bookmark-check'
                                                                            ></i>
                                                                        ) : (
                                                                            <i
                                                                                style={{ fontSize: '25px' }}
                                                                                className='mdi mdi-bookmark-outline'
                                                                            ></i>
                                                                        )}
                                                                    </span>
                                                                </td>
                                                                <td className='dropdown'>
                                                                    <Link
                                                                        className='dropdown-toggle menu'
                                                                        id='actionDropdown'
                                                                        href='#'
                                                                        data-bs-toggle='dropdown'
                                                                        aria-expanded='false'
                                                                    >
                                                                        <i className='mdi mdi-apps'></i>
                                                                    </Link>
                                                                    <div
                                                                        className='dropdown-menu dropdown-menu-right preview-list'
                                                                        aria-labelledby='actionDropdown'
                                                                    >
                                                                        <Link
                                                                            className='dropdown-item custom-drop'
                                                                            onClick={() => handleShowEdit(ec)}
                                                                        >
                                                                            <i className='mdi mdi-pencil mr-2 text-success'></i>{' '}
                                                                            <span>Edit</span>
                                                                        </Link>
                                                                        <div className='dropdown-divider'></div>
                                                                        <Link
                                                                            className='dropdown-item custom-drop'
                                                                            onClick={() => handleDelete(ec.id)}
                                                                        >
                                                                            <i className='mdi mdi-trash-can-outline mr-2 text-danger'></i>{' '}
                                                                            <span>Delete</span>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {Tag.length === 0 && (
                                        <div className='alert alert-warning text-center'>
                                            No Record
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pagination d-flex justify-content-center">
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="btn btn-primary rounded-pill me-2"
                            style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                        >
                            Previous
                        </button>
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                            <button
                                key={page}
                                onClick={() => handlePageChange(page)}
                                className={`btn btn-primary rounded-pill me-2 ${currentPage === page ? 'active' : ''
                                    }`}
                                style={
                                    currentPage === page
                                        ? { backgroundColor: '#007bff', borderColor: '#007bff' }
                                        : { backgroundColor: '#b8d2ec', borderColor: '#ced4da' }
                                }
                            >
                                {page}
                            </button>
                        ))}
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="btn btn-primary rounded-pill"
                            style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                        >
                            Next
                        </button>
                    </div>



                </div>
            </LoadingOverlay>
            <CreateTag
                show={show}
                closeModal={handleClose}
                refreshPage={handleRefresh}
            />
            <EditTag
                show={showEdit}
                closeModal={handleClose}
                refreshPage={handleRefresh}
                tag={singleTag}
            />
            <Modal show={deleteOpen} onHide={handleDeleteCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Event Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete ? Once Done cant be reversed</Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleDeleteCancel}>
                        Cancel
                    </Button>
                    <SpinnerComponent
                        loading={isDeleteLoading}
                        message='Processing...'
                        position='centered'
                    />
                    <Button
                        variant='danger'
                        onClick={handleDeleteConfirm}
                        disabled={isDeleteLoading}
                    >
                        {isDeleteLoading ? 'Deleting...' : 'Delete'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
