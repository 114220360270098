import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

export default function BaseLayout() {
    return (
        <>
            <Header />
            <div className="container-fluid page-body-wrapper">
              <Sidebar />
              <div className="main-panel" style={{ backgroundColor:'#f2edf3'}}>
                <Outlet />
                <Footer />
              </div>
            </div>
        </>
    )
}