import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import { useState ,useEffect} from 'react';
import axios from 'axios';
import { errorNotify, successNotify } from '../../../../toaster';
import { useAuth } from '../../..//../hooks/useAuthContext';
import { appURL } from '../../../../env';

export default function AddEvent({ show, closeModal, refreshPage, event }) {
    const { user } = useAuth()
    const token = user.token
    const [setEvent, setEventCatgory] = useState([]);
    const [setTag, setEventTag] = useState([]);
    const [validationError, setValidationError] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedcat, setSelectedcat] = useState([]);


    //  state to hold form data
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: '',
        end_time: '',
        start_time: '',
        date: '',
        category_id: '',
        tag_id: [],
        location: ''
    })
    //  method to reset form fields to empty
    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            status: '',
            end_time: '',
            start_time: '',
            date: '',
            category_id: '',
            tag_id: [],
            location: ''
        })
        setValidationError({})
    }

    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const { name, value, options } = e.target
        if (name == 'tag_id' && options) {
            const selectedValues = Array.from(options)
                .filter((option) => option.selected)
                .map((option) => option.value);

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: selectedValues,
            }));
        } else {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }
    }

    //  method to call then modal is closed
    const handleClose = () => {
        resetForm()
        closeModal()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        // return console.log(formData);
        await axios
            .post(`${appURL}/admin/event/add`, { ...formData, id: event.id }, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": 'multipart/form-data',
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log(res.data.message);
                successNotify(res.data.message)
                handleClose()
                refreshPage()
            })
            .catch(error => {
                if (error.response.status === 420) {
                    setValidationError(error.response.data.errors)
                    errorNotify('An error occured!')
                } else {
                    console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
                }
            })
            .finally(() => {
                setIsLoading(false)
            });
    }
    const fetchEventCategory = () => {
        axios
            .get(
                `${appURL}/admin/event/active-category-event`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                setEventCatgory(res.data)
            })
            .catch((error) => {
                console.log(error.response.data.message);
                errorNotify(
                    'An error occurred. Please try again or reload the page.'
                );
            });
    }
    const fetchEventTag = () => {
        axios
            .get(
                `${appURL}/admin/event/active-tag-event`,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                setEventTag(res.data)
                console.log(res.data);
            })
            .catch((error) => {
                console.log(error.response.data.message);
                errorNotify(
                    'An error occurred. Please try again or reload the page.'
                );
            });
    }
    useEffect(() => {
        fetchEventCategory();
        fetchEventTag();
        if (event) {
            setFormData({
                name: event.name || '',
                description: event.description || '',
                status: event.status || '',
                end_time: event.end_time || '',
                start_time: event.start_time || '',
                date: event.date || '',
                category_id: event.event_category_id || '',
                tag_id: event.tags.map(element => { return element.id}) || [],
                location: event.location || ''
            });
            setSelectedTags(event.tag_id || []);
            setSelectedcat(event.event_category_id || '')
            console.log(formData,1234);
        }
    }, [event]);
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name='name'
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Location</Form.Label>
                            <Form.Control as="textarea" rows={2} value={formData.location}
                                placeholder="Enter..."
                                name="location"
                                onChange={handleChange} />
                            {validationError.location && <small className='text-danger my-1'>{validationError.location[0]}</small>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={2} value={formData.description}
                                placeholder="Enter..."
                                name="description"
                                onChange={handleChange} />
                            {validationError.description && <small className='text-danger my-1'>{validationError.description[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="evnt_date">
                            <Form.Label>Event Date</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Enter..."
                                name='date'
                                value={formData.date}
                                onChange={handleChange}
                                required
                            />
                            {validationError.date && <small className='text-danger my-1'>{validationError.date[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="start_time">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                                type="time"
                                placeholder="Enter..."
                                name='start_time'
                                value={formData.start_time}
                                onChange={handleChange}
                                required
                            />
                            {validationError.start_time && <small className='text-danger my-1'>{validationError.start_time[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="end_time">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control
                                type="time"
                                placeholder="Enter..."
                                name='end_time'
                                value={formData.end_time}
                                onChange={handleChange}
                                requiredf
                            />
                            {validationError.end_time && <small className='text-danger my-1'>{validationError.end_time[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="category_id">
                            <Form.Label>Event Category</Form.Label>
                            <Form.Select name='category_id' className="mb-3" aria-label="Default select example" onChange={handleChange}>
                                <option value=''>Select Event Category</option>
                                {setEvent.map((ev, i) => (
                                    <option value={ev.id} key={ev.id} selected={formData.category_id === ev.id}>{ev.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="tag_id">
                            <Form.Label>Event Tag</Form.Label>
                            <Form.Select multiple name='tag_id'  className="mb-3" aria-label="Default select example" onChange={handleChange}>
                                <option value=''>Select Event Tag</option>
                                {setTag.map((tg, i) => (
                                    <option value={tg.id} key={tg.id} selected={ (formData.tag_id && formData.tag_id[i] && formData.tag_id[i].id) === tg.id}>{tg.name}</option>
                                ))}
                            </Form.Select>
                            {validationError.tag_id && <small className='text-danger my-1'>{validationError.tag_id[0]}</small>}
                        </Form.Group>
                        <div className='mb-3'>
                            <label htmlFor='status' style={{ display: 'block' }}>
                                <span>Status</span>
                                <select
                                    className='form-control mt-2'
                                    id='status'
                                    value={formData.status}
                                    onChange={handleChange}
                                    name='status'
                                >
                                    <option value=''>--Select Status--</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </div>
    );
}