import { useState } from 'react'
// import MyComponent from 'react-fullpage-custom-loader'
import { useLogin } from './hooks/useLogin'

export default function LoginPage() {
    const [formData, setFormData] = useState({
        email: '', password: ''
    })
    const { isLoading, apiError, validationError, login } = useLogin()

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData(prevFormData => (
            {...prevFormData, [name]: value}
        ))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        login(formData)
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth">
                    <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left p-5">
                                <div className="brand-logo text-center">
                                <img style={{ height: '100px', width:'100px', borderRadius:'100%' }} src="assets/images/website/GDC-logo.png" alt="logo" />
                                </div>
                                <h4>Hello! let's get started</h4>
                                <h6 className="font-weight-light">Sign in to continue.</h6>
                                {apiError && 
                                    <div className='alert alert-danger'>
                                        {  apiError }
                                    </div>
                                }
                                <form className="pt-3" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input 
                                            type="email" 
                                            className="form-control form-control-lg" 
                                            name='email'
                                            value={formData.email}
                                            placeholder="Email" 
                                            onChange={handleChange}
                                        />
                                        {validationError.email && <small className='text-danger my-1'>{validationError.email[0]}</small>}
                                    </div>
                                    <div className="form-group mb-1">
                                        <input 
                                            type="password" 
                                            className="form-control form-control-lg" 
                                            placeholder="Password" 
                                            value={formData.password}
                                            name='password'
                                            onChange={handleChange}
                                        />
                                        {validationError.password && <small className='text-danger my-1'>{validationError.password[0]}</small>}
                                    </div>
                                    <div className="d-flex mb-4">
                                        <a href="#" className="auth-link text-black ml-auto">Forgot password?</a>
                                    </div>
                                    <div className="mt-4">
                                        <button type='submit' className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn">
                                            {!isLoading && 'SIGN IN'}
                                            {isLoading && 
                                                <>
                                                    <div className="spinner-grow text-light" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-warning" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    <div className="spinner-grow text-danger" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </>
                                            }
                                        </button>
                                    </div>
                                    {/* <div className="text-center mt-4 font-weight-light"> Don't have an account? <a href="register.html" className="text-primary">Create</a>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}