import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { errorNotify, successNotify } from '../../toaster';
import { useAuth } from '../../hooks/useAuthContext.js';
import { appURL } from '../../env';

export default function EditBanner({ show, closeModal, refreshPage, banner }) {
    const { user } = useAuth()
    const token = user.token

    const[validationError, setValidationError] = useState({})
    const[isLoading, setIsLoading] = useState(false)

    //  state to hold form data
    const [formData, setFormData] = useState({
        slider_text: '',
        link:'',
        status: ''
    })

      //    banner image preview
      const [bannerImgUrl, setBannerImgUrl] = useState('')

      //    method for image select
      const changeHandler = (event) => {
          setValidationError({})
          const { name, files } = event.target
          setFormData(prevFormData => (
              { ...prevFormData, [name]: files[0] }
          ))
          setBannerImgUrl(URL.createObjectURL(files[0]))
      };

      //  method to reset form fields to empty
    const resetForm  = () => {
      setFormData({
          slider_text: '',
          link:'',
          status: ''
      })
      setBannerImgUrl('')
      setValidationError({})
    }

    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const {name, value}  = e.target

        setFormData(prevFormData => (
            {...prevFormData, [name]: value}
        ))
    }

    //  method to call then modal is closed
    const handleClose = () => {
        resetForm()
        closeModal()
    }

    //  method to submit form
    const handleSubmit  = async(e) => {
        e.preventDefault()
        setIsLoading(true)
        setValidationError({})
            await axios
            .post(`${appURL}/background-slider/store-or-update`, {
            ...formData,
            id: banner.id, // Include the id of the banner
            }, {
            headers: {
                "Accept": "application/json",
                "Content-Type": 'multipart/form-data',
            }
            })
            .then((res) => {
                console.log(res.data);
                successNotify(res.data.message)
                handleClose()
                refreshPage()
            })
            .catch(error => {
                if(error.response.status === 405) {
                    setValidationError(error.response.data)
                    errorNotify('An error occured!')
                } else {
                    console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
                }
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    useEffect(() => {
        if (banner) {
          setFormData({
            slider_text: banner.slider_text || '',
            link: banner.link || '',
            status: banner.status || ''
          });
        }
      }, [banner]);

    return (
        <>
          <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Add Banner</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {bannerImgUrl && (
                    <div className='image-display-wrapper d-flex mb-4' 
                        style={{ justifyContent: 'center' }}>
                        <img src={bannerImgUrl} alt='ads'
                            className='img-fluid' width={300}
                        />
                    </div>
                )}
                <div className='mb-3'>
                    <div>
                        <label htmlFor='status' style={{display: 'block'}}>
                            <span>Banner Image</span>
                            <input 
                                type='file' 
                                className='form-control'
                                accept=".jpg, .jpeg, .png"
                                name="slider_image"
                                onChange={changeHandler}
                            />
                        </label>
                    </div>
                    {validationError.slider_image && <small className='text-danger my-1'>{validationError.slider_image[0]}</small>}
                </div>
                <Form.Group className="mb-3" controlId="formBasicname">
                  <Form.Label>Slider Name</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Enter slider name" 
                    name='slider_text' 
                    value={formData.slider_text}
                    onChange={handleChange}
                  />
                  {validationError.slider_text && <small className='text-danger my-1'>{validationError.slider_text[0]}</small>}

                  <Form.Label>Link</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Link" 
                    name='link' 
                    value={formData.link}
                    onChange={handleChange}
                  />
                  {validationError.link && <small className='text-danger my-1'>{validationError.link[0]}</small>}
                  
                </Form.Group>

                <div className='mb-3'>
                    <label htmlFor='status' style={{display: 'block'}}>
                        <span>Status</span>
                        <select 
                            className='form-control mt-2' 
                            id='status'
                            value={formData.status}
                            onChange={handleChange}
                            name='status'
                        >
                            <option value=''>--Select Status--</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </label>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="link" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type='submit'>
                    save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ToastContainer />
        </>
    );
}
