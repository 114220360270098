import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { errorNotify, successNotify } from '../../../toaster';
import { useAuth } from '../../../hooks/useAuthContext';
import { appURL } from '../../../env';

export default function CreateVideo({ show, closeModal, refreshPage, video }) {


    const handleClose = () => {
        closeModal()
        refreshPage()
    }
    useEffect(() => {

    }, []);
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        video &&
                        <div className='w-100'>
                            <video autoPlay controls className='w-100' id='myVideo'>
                                <source src={video.video_url} type={video.video_type} />
                            </video>
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} className='d-block'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div>
    );
}