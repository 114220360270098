import { Link } from "react-router-dom"
import { useAuth } from "../../hooks/useAuthContext"

export default function Sidebar() {
    const {user} =  useAuth()
    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                {user && <li className="nav-item nav-profile">
                    <Link href="#" className="nav-link">
                        <div className="nav-profile-image">
                            <img src={user.user.avatar} alt="profile" />
                            <span className="login-status online"></span>
                        </div>
                        <div className="nav-profile-text d-flex flex-column">
                            <span className="font-weight-bold mb-2 text-capitalize">{user.user.lastname} {user.user.firstname}</span>
                            <span className="text-secondary text-small text-capitalize">{user.user.account_type}</span>
                        </div>
                        <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
                    </Link>
                </li>}
                <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                        <span className="menu-title">Dashboard</span>
                        <i className="mdi mdi-home menu-icon"></i>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                        <span className="menu-title">Users</span>
                        <i className="menu-arrow"></i>
                        <i className="mdi mdi-account-group-outline menu-icon"></i>
                    </Link>
                    <div className="collapse" id="ui-basic">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> 
                                <Link className="nav-link" to="/users">Clients</Link>
                            </li>
                            {/* <li className="nav-item"> 
                                <Link className="nav-link" to="/users/admin">Admin</Link>
                            </li> // <li className="nav-item">
                //     <a className="nav-link" data-bs-toggle="collapse" href="#products" aria-expanded="false" aria-controls="products">
                //         <span className="menu-title"> Sermons</span>
                //         <i className="menu-arrow"></i>
                //         <i className="mdi mdi-star-box menu-icon"></i>
                //     </a>
                //     <div className="collapse" id="products">
                //         <ul className="nav flex-column sub-menu">
                //             <li className="nav-item"> 
                //                 <Link className="nav-link" to="/categories">Video Sermons</Link>
                //             </li>
                //             <li className="nav-item"> 
                //                 <Link className="nav-link" to="/products">Audio Sermons</Link>
                //             </li>
                //             {/* <li className="nav-item"> 
                //                 <Link className="nav-link" to="/users/admin">Admin</Link>
                //             </li> 
                //         </ul>
                //     </div>
    // </li>*/}
                        </ul>
                    </div>
                </li>
                
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="collapse" href="#events" aria-expanded="false" aria-controls="events">
                        <span className="menu-title"> Manage Events</span>
                        <i className="menu-arrow"></i>
                        <i className="mdi mdi-star-box menu-icon"></i>
                    </a>
                    <div className="collapse" id="events">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> 
                                <Link className="nav-link" to="/event-category">Category</Link>
                            </li>
                            <li className="nav-item"> 
                                <Link className="nav-link" to="/event">Event</Link>
                            </li>
                            <li className="nav-item"> 
                                <Link className="nav-link" to="/tag">Tag</Link>
                            </li>
                            {/* <li className="nav-item"> 
                                <Link className="nav-link" to="/users/admin">Admin</Link>
                            </li> */}
                        </ul>
                    </div>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="collapse" href="#videos" aria-expanded="false" aria-controls="videos">
                        <span className="menu-title"> Manage Sermon</span>
                        <i className="menu-arrow"></i>
                        <i className="mdi mdi-star-box menu-icon"></i>
                    </a>
                    <div className="collapse" id="videos">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> 
                                <Link className="nav-link" to="/video">Manage Video</Link>
                            </li>
                        </ul>
                    </div>
                </li>
                
                <li className="nav-item">
                    <Link className="nav-link" to="/banners">
                        <span className="menu-title">Banners</span>
                        <i className="mdi mdi-vector-difference-ba menu-icon"></i>
                    </Link>
                </li>
                
                <li className="nav-item">
                    <Link className="nav-link" to="/gallery">
                        <span className="menu-title">Galleries</span>
                        <i className="mdi mdi-vector-difference-ba menu-icon"></i>
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link className="nav-link" href="pages/forms/basic_elements.html">
                        <span className="menu-title">Forms</span>
                        <i className="mdi mdi-format-list-bulleted menu-icon"></i>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" href="pages/charts/chartjs.html">
                        <span className="menu-title">Charts</span>
                        <i className="mdi mdi-chart-bar menu-icon"></i>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" href="pages/tables/basic-table.html">
                        <span className="menu-title">Tables</span>
                        <i className="mdi mdi-table-large menu-icon"></i>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" data-bs-toggle="collapse" href="#general-pages" aria-expanded="false" aria-controls="general-pages">
                        <span className="menu-title">Sample Pages</span>
                        <i className="menu-arrow"></i>
                        <i className="mdi mdi-medical-bag menu-icon"></i>
                    </Link>
                    <div className="collapse" id="general-pages">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item"> <Link className="nav-link" href="pages/samples/blank-page.html"> Blank Page </Link></li>
                            <li className="nav-item"> <Link className="nav-link" href="pages/samples/login.html"> Login </Link></li>
                            <li className="nav-item"> <Link className="nav-link" href="pages/samples/register.html"> Register </Link></li>
                            <li className="nav-item"> <Link className="nav-link" href="pages/samples/error-404.html"> 404 </Link></li>
                            <li className="nav-item"> <Link className="nav-link" href="pages/samples/error-500.html"> 500 </Link></li>
                        </ul>
                    </div>
                </li>
                <li className="nav-item sidebar-actions">
                    <span className="nav-link">
                        <div className="border-bottom">
                            <h6 className="font-weight-normal mb-3">Projects</h6>
                        </div>
                        <button className="btn btn-block btn-lg btn-gradient-primary mt-4">+ Add a project</button>
                        <div className="mt-4">
                            <div className="border-bottom">
                                <p className="text-secondary">Categories</p>
                            </div>
                            <ul className="gradient-bullet-list mt-4">
                                <li>Free</li>
                                <li>Pro</li>
                            </ul>
                        </div>
                    </span>
                </li> */}
            </ul>
        </nav>
    )
}