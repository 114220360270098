import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { errorNotify, successNotify } from '../../../toaster';
import { useAuth } from '../../../hooks/useAuthContext';
import { appURL } from '../../../env';
import FloatingLabel from 'react-bootstrap/FloatingLabel';


export default function CreateVideo({ show, closeModal, refreshPage }) {
    const { user } = useAuth()
    const token = user.token
    const [setEvent, setEventCatgory] = useState([]);
    const [setTag, setEventTag] = useState([]);
    const [validationError, setValidationError] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [video_url, setVideoUrl] = useState('');
    const [video_type, setVideoType] = useState('');

    //  state to hold form data
    const [formData, setFormData] = useState({
        name: '',
        file:null,
        sermon_by:"",
        video_type:"",
        description:""
    })
    //  method to reset form fields to empty
    const resetForm = () => {
        setFormData({
            name: '',
            file:null,
            sermon_by:"",
            video_type:"",
            description:""
        })
        setVideoUrl('')
        setVideoType('')
        setValidationError({})
    }
    const handleChangeInput = (e) => {
        const { name, value } = e.target

        setFormData(prevFormData => (
            { ...prevFormData, [name]: value }
        ))
    }


    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const { name, files } = e.target;
        setFormData(prevFormData => (
            { ...prevFormData, [name]: files[0] }
        ))
        let v = URL.createObjectURL(files[0]);
        setVideoType(files[0].type);
        setVideoUrl(v);

    }

    //  method to call then modal is closed
    const handleClose = () => {
        resetForm()
        closeModal()
    }
 //  method to submit form
 const handleSubmit  = async(e) => {
    e.preventDefault()
    setIsLoading(true)
    const data = new FormData();
    data.append('file',formData.file);
    data.append('name',formData.name);
    data.append('sermon_by',formData.sermon_by);
    data.append('video_type',video_type);
    data.append('description',formData.description);
    await axios
        .post(`${appURL}/admin/video/save`, data, {
            headers: {
                "Accept": "application/json",
                "Content-Type": 'multipart/form-data',
                "Authorization" : `Bearer ${token}`
            }
        })
        .then((res) => {
            console.log(res.data);
            successNotify(res.data.message)
            handleClose()
            refreshPage()
        })
        .catch(error => {
            if(error.response.status === 420) {
                setValidationError(error.response.data.errors)
                errorNotify('An error occured!')
            } else {
            console.log(error.response.data.message)
                errorNotify('An error occured. Please, try again or reload page.')
            }
        })
        .finally(() => {
            setIsLoading(false)
        });
}
  

    useEffect(() => {

    }, []);
    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload Video</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {
                                (video_url) &&
                                <video controls className='w-100 h-100' autoPlay>
                                    <source src={video_url} type={video_type} />
                                </video>
                            }
                        </div>
                        <p className='alert alert-warning p-2'>Information with red star are advised to be strictly adhered to</p>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name='name'
                                value={formData.name}
                                onChange={handleChangeInput}
                                required
                            />
                            {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <Form.Label>Sermon By</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name='sermon_by'
                                value={formData.sermon_by}
                                onChange={handleChangeInput}
                                required
                            />
                            {validationError.sermon_by && <small className='text-danger my-1'>{validationError.sermon_by[0]}</small>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="video">
                            <Form.Label className='pb-2'>Upload Video
                            <small className='d-block pt-0'>100mb size limit <sup className='text-danger'><strong>*</strong></sup></small>
                            </Form.Label>
                            <Form.Control
                                type="file"
                                name='file'
                                onChange={handleChange}
                                required
                            />
                            {validationError.video_url && <small className='text-danger my-1'>{validationError.video_url[0]}</small>}
                        
                        </Form.Group>
                        <FloatingLabel controlId="floatingTextarea2" label="Description">
                            <Form.Label className='pb-2'>
                            {/* <small className='d-block pt-0'>100mb size limit <sup className='text-danger'><strong>*</strong></sup></small> */}
                            </Form.Label>
                            <Form.Control
                                 as="textarea"
                                name='description'
                                onChange={handleChangeInput}
                                required
                            />
                            {validationError.description && <small className='text-danger my-1'>{validationError.description[0]}</small>}
                        
                        </FloatingLabel>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="link" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ToastContainer />
        </div>
    );
}