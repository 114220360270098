import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import axios from 'axios';
import { errorNotify, successNotify } from '../../../toaster';
import { useAuth } from '../../../hooks/useAuthContext.js';
import { appURL } from '../../../env';

export default function AddGallery({ show, closeModal, refreshPage }) {
    const { user } = useAuth()
    const token = user.token

    const[validationError, setValidationError] = useState({})
    const[isLoading, setIsLoading] = useState(false)

    //  state to hold form data
    const [formData, setFormData] = useState({
        name: '',
        status: ''
    })

   

    //    banner image preview
    const [bannerImgUrl, setBannerImgUrl] = useState('')

    //    method for image select
    const changeHandler = (event) => {
        setValidationError({})
        const { name, files } = event.target
        setFormData(prevFormData => (
            { ...prevFormData, [name]: files[0] }
        ))
        setBannerImgUrl(URL.createObjectURL(files[0]))
    };

    //  method to reset form fields to empty
    const resetForm  = () => {
        setFormData({
            name:'',
            status: ''
        })
        setBannerImgUrl('')
        setValidationError({})
    }

    //  listen for changes on form inputs and store in state
    const handleChange = (e) => {
        const {name, value}  = e.target

        setFormData(prevFormData => (
        {...prevFormData, [name]: value}
        ))
    }

    //  method to call then modal is closed
    const handleClose = () => {
        resetForm()
        closeModal()
    }

    //  method to submit form
    const handleSubmit  = async(e) => {
        e.preventDefault()
        setIsLoading(true)
        await axios
            .post(`${appURL}/admin/gallery/save`, formData, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": 'multipart/form-data',
                    "Authorization" : `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log(res.data);
                successNotify(res.data.message)
                handleClose()
                refreshPage()
            })
            .catch(error => {
                if(error.response.status === 420) {
                    setValidationError(error.response.data.errors)
                    errorNotify('An error occured!')
                } else {
                console.log(error.response.data.message)
                    errorNotify('An error occured. Please, try again or reload page.')
                }
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    return (
        <>
          <Modal show={show} onHide={handleClose}>
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Gallery</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {bannerImgUrl && (
                    <div className='image-display-wrapper d-flex mb-4' 
                        style={{ justifyContent: 'center' }}>
                        <img src={bannerImgUrl} alt='ads'
                            className='img-fluid' width={300}
                        />
                    </div>
                )}
                <div className='mb-3'>
                    <div>
                        <label htmlFor='status' style={{display: 'block'}}>
                            <span>Gallery Image</span>
                            <input 
                                type='file' 
                                className='form-control'
                                accept=".jpg, .jpeg, .png"
                                name="gallery_image"
                                onChange={changeHandler}
                                required
                            />
                        </label>
                    </div>
                    {validationError.gallery_image && <small className='text-danger my-1'>{validationError.gallery_image[0]}</small>}
                </div>
                <Form.Group className="mb-3" controlId="formBasicname">
                  <Form.Label>Name</Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Enter gallery name" 
                    name='name' 
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  {validationError.name && <small className='text-danger my-1'>{validationError.name[0]}</small>}

                  
                </Form.Group>

                <div className='mb-3'>
                    <label htmlFor='status' style={{display: 'block'}}>
                        <span>Status</span>
                        <select 
                            className='form-control mt-2' 
                            id='status'
                            value={formData.status}
                            onChange={handleChange}
                            name='status'
                        >
                            <option value=''>--Select Status--</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </label>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="link" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" type='submit'>
                    save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ToastContainer />
        </>
    );
}
